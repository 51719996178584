
import { defineComponent } from 'vue';

import {
  Getters as SubscriptionGetters,
  Actions as SubscriptionActions,
} from '@/store/enums/SubscriptionEnums';
import { Actions as InvoiceActions } from '@/store/enums/InvoiceEnums';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'checkout-success-page',
  data: () => ({
    toRedirect: false,
    timer: 30,
  }),
  mounted() {
    setInterval(async () => {
      this.timer = this.timer - 1;
    }, 1000);

    this.loadInit();
  },
  computed: {
    ...mapGetters({
      subscription: SubscriptionGetters.GET_SUBSCRIPTION,
    }),
  },
  methods: {
    ...mapActions({
      fetchSubscription: SubscriptionActions.FETCH_CURRENT_SUBSCRIPTION,
      fetchInvoices: InvoiceActions.FETCH_INVOICES,
    }),

    async loadInit() {
      await this.fetchSubscription().catch(() => {
        this.toRedirect = false;
      });

      const subscriptionId = await this.subscription.stripe_id;

      await this.fetchInvoices(subscriptionId).catch(() => {
        this.toRedirect = false;
      });

      setTimeout(() => {
        this.$router.push({ name: 'dashboard' });
      }, 5000);
    },
  },
});
